import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, InjectionToken, inject } from '@angular/core';

export interface DynamicFormsConfiguration {
  baseUrl: URL;
}

export interface CreateSubmissionResponse {
  success: string[];
  errors: string[];
}

export const dynamicFormsConfigurationToken = new InjectionToken<DynamicFormsConfiguration>('DynamicFormsConfiguration');

@Injectable({
  providedIn: 'root',
})
export class DynamicFormsService {

  private configuration = inject(dynamicFormsConfigurationToken);
  private http = inject(HttpClient);

  public postSubmission(captchaToken: string, formUniqueIdentifier: string, fields: { [fieldName: string]: string }) {
    return this.http.post<CreateSubmissionResponse>(`${this.configuration.baseUrl}/submissions`, {
      formUniqueIdentifier: formUniqueIdentifier,
      fields: fields,
    },{
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${captchaToken}`,
      }),
    });
  }

}
